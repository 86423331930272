<script setup lang="ts">
import { NModal } from '@likui628/naive-ui';
import { ref } from 'vue';
import QRScan from './QRScan.vue';

const showModal = ref(false);

const props = defineProps<{
  validator?: (val: string) => boolean
}>();

const emit = defineEmits<{
  change: [result: string];
}>();

const onClick = () => {
  showModal.value = true;
};
const onChange = (val: string) => {
  emit('change', val);
  showModal.value = false;
};
</script>

<template>
  <div>
    <span @click="onClick">
      <slot></slot>
    </span>
    <NModal v-model:show="showModal">
      <QRScan @change="onChange" :validator="props.validator">
        <template #footer>
          <slot name="modalFooter"></slot>
        </template>
      </QRScan>
    </NModal>
  </div>
</template>

<style scoped>

</style>