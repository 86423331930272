import { defineStore } from 'pinia';
import { ref, watch } from 'vue';

export type Resolution = 'sd' | 'hd'

export const useStyle3dStore = defineStore('style3d', () => {
  const loaded = ref<boolean>(false);
  const ready = ref<boolean>(false);

  const resolution = ref<Resolution>(sessionStorage.getItem('resolution') as Resolution ?? 'sd');

  const reset = () => {
    loaded.value = false;
    ready.value = false;
  };

  watch(resolution, (newVal) => {
    sessionStorage.setItem('resolution', newVal.toString());
    location.reload();
  });

  return { resolution, loaded, ready, reset };
});
