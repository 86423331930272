import { ref, inject, provide, type InjectionKey, type Ref } from 'vue';


interface Loading {
  isLoading: Ref<boolean>;
  setLoading: (isLoading: boolean) => void;
}

const loadingKey = Symbol('loading') as InjectionKey<Loading>;

export function useLoadingProvider() {
  const isLoading = ref(false);

  function setLoading(state: boolean) {
    isLoading.value = state;
  }

  provide(loadingKey, { isLoading, setLoading });

  return {
    isLoading,
    setLoading
  };
}

export function useLoading() {
  const loading = inject(loadingKey);


  if (!loading) {
    throw new Error('useLoading must be used within a useLoadingProvider');
  }

  return loading;
}
