import { createI18n, type Locale } from 'vue-i18n';

export const i18n = createI18n({
  globalInjection: true,
  legacy: false,
  locale: '',
  messages: {}
});

export const $t = i18n.global.t;

export function setI18nLanguage(locale: Locale) {
  i18n.global.locale.value = locale;

  document?.querySelector('html')?.setAttribute('lang', locale);
}
