<script setup lang="ts">
import { BrowserCodeReader } from '@zxing/browser';
import { onMounted, ref } from 'vue';
import { NSelect } from '@likui628/naive-ui';
import { $t } from '@repo/locales';
import QrReader from './QrReader.vue';

const props = defineProps<{
  validator?: (val: string) => boolean
}>();

const deviceId = ref<string>();
const deviceOptions = ref<{ label: string, value?: string }[]>([]);

const hasDevices = ref<boolean>();
const hasPermission = ref<boolean>();

const validationFailure = ref<boolean>();

const emit = defineEmits<{
  change: [value: string]
}>();

const chooseCamera = () => {
  const cameraDevice = localStorage.getItem('cameraDevice');
  if (cameraDevice) {
    const found = deviceOptions.value.find(item => item.label === cameraDevice);
    if (found) {
      deviceId.value = found.value;
    }
  }
};

async function initCamera() {
  try {
    const videoInputDevices = await BrowserCodeReader.listVideoInputDevices();
    hasPermission.value = true;
    if (videoInputDevices[0]) {
      hasDevices.value = true;
      deviceOptions.value = [
        {
          label: 'default',
          value: undefined
        },
        ...videoInputDevices.map(dev => ({ label: dev.label, value: dev.deviceId }))
      ];
    } else {
      hasDevices.value = false;
      console.error('No video input devices found');
    }
  } catch (e) {
    hasPermission.value = false;
    console.error('Can\'t enumerate devices, method not supported.');
  }
  chooseCamera();
}

onMounted(async () => {
  await initCamera();
});

const onSelectCamera = async (val: string | undefined, { label }: any) => {
  deviceId.value = val;
  localStorage.setItem('cameraDevice', label);
};

const onScan = (text: string) => {
  const isValid = props.validator ? props.validator(text) : true;

  if (isValid) {
    emit('change', text);
  } else {
    validationFailure.value = true;
    setTimeout(() => {
      validationFailure.value = false;
    }, 1000);
  }
};
</script>

<template>
  <div class="camera-container">
    <div class="header">
      <NSelect
        class="camera-select"
        v-model:value="deviceId"
        @update-value="onSelectCamera"
        :options="deviceOptions"
        size="small"
      />
      <div class="header-extra">
        <slot name="headerRight"></slot>
      </div>
    </div>
    <div class="video-container">
      <div class="overlay" v-if="!hasPermission">
        <div>{{ $t('message.no-device-permission') }}</div>
      </div>
      <div class="overlay" v-else-if="!hasDevices">
        <div>{{ $t('message.no-devices') }}</div>
      </div>
      <div class="overlay" v-else-if="validationFailure">
        <div>{{ $t('message.no-corresponding-material') }}</div>
      </div>
      <QrReader v-else :device-id="deviceId" @change="onScan" />
    </div>

    <div class="footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<style scoped>
.camera-container {
  width: 100%;
  height: auto;
  position: relative;
  background-color: #000;
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  z-index: 9999;
}

.footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
}

.camera-select {
  z-index: 999;
  flex-grow: 0;
  flex-shrink: 0;
  width: 200px;
}

.header-extra {
  flex: 1;
}

.video-container {
  width: 100%;
  height: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.video-container .overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.camera-container video {
  width: 100%;
  height: 100%;
}
</style>