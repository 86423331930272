import type { Pinia } from 'pinia';

import type { App } from 'vue';

import { createPinia } from 'pinia';

let pinia: Pinia;

export function initStores(app: App) {
  pinia = createPinia();
  app.use(pinia);
  return pinia;
}

export function resetAllStores() {
  if (!pinia) {
    console.error('Pinia is not installed');
    return;
  }
  const allStores = (pinia as any)._s;

  for (const [_key, store] of allStores) {
    store.$reset();
  }
}
