<script setup lang="ts">
import { BrowserCodeReader, BrowserQRCodeReader, type IScannerControls } from '@zxing/browser';
import { onBeforeUnmount, onMounted, ref, watch } from 'vue';

const videoRef = ref<HTMLVideoElement>();
const reader = new BrowserQRCodeReader();

const props = defineProps<{ deviceId?: string }>();

const emit = defineEmits<{
  change: [text: any]
}>();

let controls: IScannerControls;
const scanQR = async () => {
  controls = await reader.decodeFromVideoDevice(props.deviceId, videoRef.value, (res, _err) => {
    if (res) {
      emit('change', res.getText());
    }
  });
};

watch(() => props.deviceId, async () => {
    controls?.stop();
    await scanQR();
  }
);

onMounted(() => {
  scanQR();
});

onBeforeUnmount(() => {
  controls?.stop();
  BrowserCodeReader.releaseAllStreams();
});
</script>

<template>
  <div class="video-container">
    <video ref="videoRef" />
  </div>
</template>

<style scoped>

.video-container {
  width: 100%;
  height: 100%;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-container video {
  width: 100%;
  height: 100%;
}
</style>