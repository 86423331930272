<script setup lang="ts">
const props = defineProps<{ logoUrl?: string }>();
</script>
<template>
  <div class="logo-container">
    <img :src="props.logoUrl" alt="logo" v-show="props.logoUrl" />
    <div></div>
  </div>
</template>
<style scoped>
.logo-container {
  padding: 10px;
  height: 60px;
  display: flex;
  justify-content: left;
  align-items: start;
}

.logo-container img {
  flex: 0;
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}

.logo-container div {
  flex: 1;
}
</style>
