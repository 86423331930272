import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import type { Metadata, MetadataProperty, Relation, Shape } from '@repo/style3d';

interface GroupedData {
  [key: string]: {
    name: string;
    value: string;
  }[];
}


export const useRelationStore = defineStore('relation', () => {
  const relation = ref<Omit<Relation,'shape'>>();
  const shapeList = ref<Shape[]>([]);

  function setRelation(val?: Relation) {
    relation.value = val;
  }

  function setShape(val: Shape[]) {
    shapeList.value = val;
  }

  function setMetadata(val: Metadata) {
    if (relation.value) {
      relation.value.metadata = val;
    }
  }

  const property = computed(() => {
    const groupedData = relation.value?.metadata?.property.reduce((acc: GroupedData, property: MetadataProperty) => {
      const title = property.title || 'default';
      if (!acc[title]) {
        acc[title] = [];
      }
      acc[title] = acc[title]!.concat(property.data);
      return acc;
    }, {});

    // 暂时未做分组
    return groupedData?.default;
  });

  return {
    relation,
    setRelation,
    shapeList,
    setShape,
    setMetadata,
    property
  };
});
