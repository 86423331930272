import type { App } from 'vue';
import type { Locale } from 'vue-i18n';
import { unref } from 'vue';

import type { ImportLocaleFn, LoadMessageFn, LocaleSetupOptions, SupportedLanguagesType } from './typing.ts';
import { i18n, setI18nLanguage } from './i18n.ts';

const modules = import.meta.glob('./langs/*.json');
const localesMap = loadLocalesMap(modules);

let loadMessages: LoadMessageFn;

function loadLocalesMap(modules: Record<string, () => Promise<unknown>>) {
  const localesMap: Record<Locale, ImportLocaleFn> = {};

  for (const [path, loadLocale] of Object.entries(modules)) {
    const key = path.match(/([\w-]*)\.(yaml|yml|json)/)?.[1];
    if (key) {
      localesMap[key] = loadLocale as ImportLocaleFn;
    }
  }

  return localesMap;
}

async function setupI18n(app: App, options: LocaleSetupOptions) {
  const { defaultLocale = 'zh' } = options;

  loadMessages = options.loadMessages || (async () => ({}));

  app.use(i18n);
  await loadI18nMessages(defaultLocale);
}

export async function loadI18nMessages(lang: SupportedLanguagesType) {
  if (unref(i18n.global.locale) === lang) {
    return setI18nLanguage(lang);
  }

  const message = await localesMap[lang]?.();

  if (message?.default) {
    i18n.global.setLocaleMessage(lang, message.default);
  }

  const mergeMessage = await loadMessages(lang);
  i18n.global.mergeLocaleMessage(lang, mergeMessage);

  return setI18nLanguage(lang);
}


export { setupI18n, loadLocalesMap, type SupportedLanguagesType };
export { $t } from './i18n';
export { useI18n } from 'vue-i18n';
